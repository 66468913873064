<template>
  <div>
    <el-alert v-if="xianShi" title="未连接,请配置设备WIFI并连上网络" type="error" show-icon center></el-alert>
    <div class="caozuo" v-show="active == 0">
      <div class="zuo">
        <div class="shang">
          <div style="display:-webkit-flex;flex-direction:row;">
            <div class="shang1" style="width:89%;height:96px;background-color: blue;color: rgb(205, 245, 245);font-size:5em;text-align: center;">{{ deviceMessage.PV != undefined ? (deviceMessage.PV == 0 ? '0' : deviceMessage.PV.toFixed(2)) : '--------' }}</div>
            <div style="width:11%;height:96px;display:-webkit-flex;flex-direction:column;justify-content: space-between;">
              <div style="width:100%;height:20px;color:black;">
                <font size="4%">PV</font>
              </div>
              <div style="width:100%;height:20px;color:black;">
                <font size="4%">℃</font>
              </div>
            </div>
          </div>
          <div style="display:-webkit-flex;flex-direction:row;">
            <div class="shang2" style="width:90%;height:96px;background-color: blue;color: rgb(205, 245, 245);font-size:5em;text-align: center;">{{ deviceMessage.SV != undefined ? (deviceMessage.SV == 0 ? '0' : deviceMessage.SV.toFixed(2)) : '--------' }}</div>
            <div style="width:11%;height:96px;display:-webkit-flex;flex-direction:column;justify-content: space-between;">
              <div style="width:100%;height:20px;color:black;">
                <font size="4%">SV</font>
              </div>
              <div style="width:100%;height:20px;color:black;">
                <font size="4%">℃</font>
              </div>
            </div>
          </div>
        </div>
        <div class="xia">
          <div style="display:-webkit-flex;flex-direction: row;justify-content: space-between;width:80%;margin: 2%  2%  0 8%;">
            <div class="circle" :style="{ backgroundColor: zhiLeng1, borderRadius: '50%', width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center',color:'white' }">制冷</div>
            <div class="circle" :style="{ backgroundColor: xunHuan, borderRadius: '50%', width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center',color:'white' }">循环</div>
            <div class="circle" :style="{ backgroundColor: jiAre, borderRadius: '50%', width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center',color:'white' }">加热</div>
          </div>
        </div>
      </div>
      <div class="you">
        <button :disabled="zhiLengshow" style="height:88px;width:92%;margin-left: 4%;background-color: yellow; border-radius: 4%;" @click="handleZhiLeng">
          <font color="black" size="5%">制冷<i class="vxe-icon-dot" :style="{ color: zhiLeng }"></i></font>
        </button>
        <button :disabled="xunHuanshow" style="height:88px;width:92%;margin-left: 4%;background-color: aqua; border-radius: 4%;" @click="handleXunHuan">
          <font color="black" size="5%">循环<i class="vxe-icon-dot" :style="{ color: xunHuan }"></i></font>
        </button>
        <button :disabled="wenKongshow" style="height:88px;width:92%;margin-left: 4%;background-color: chartreuse; border-radius: 4%;" @click="handlWenKong">
          <font color="black" size="5%">温控<i class="vxe-icon-dot" :style="{ color: wenKong }"></i></font>
        </button>
      </div>
      <div class="tubiao">
        <div id="cwChart"></div>
      </div>
    </div>
    <div class="renwu" v-show="active == 1">
      <van-dialog v-model="show" :showCancelButton="true" confirmButtonText="保存" @confirm="addRow">
        <van-cell-group inset>
          <van-field v-model="searchObj1.name" label="名称" placeholder="请填写名称" />
          <van-field v-model="searchObj1.interval" label="间隔(分钟)" placeholder="请填写间隔(分钟)" />
        </van-cell-group>
      </van-dialog>
      <div class="biaoge" style="width:100%">
        <el-table :data="tableData" border>
          <el-table-column prop="name" label="名称">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="100">
          </el-table-column>
          <el-table-column label="间隔" align="right" width="60">
            <template slot-scope="scope">{{ scope.row.interval }}min</template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="70">
            <template slot-scope="scope"><van-tag :type="scope.row.executed == true ? 'success' : 'danger'">{{ scope.row.executed ? '已执行' : '未执行' }}</van-tag></template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="50">
            <template slot-scope="scope"><van-button @click="deleteRow(scope.row)" type="danger" size="mini" icon="delete-o" round></van-button></template>
          </el-table-column>
        </el-table>
        <van-pagination style="margin-top:5px" v-model="searchObj.currentPage" :total-items="searchObj.total" :items-per-page="searchObj.pageSize" force-ellipses @change="getList()" />
      </div>
      <div style="margin: 5px 10px 0 10px">
        <van-row type="flex" justify="space-between">
          <van-col span="3"><van-button type="info" size="large" @click="revoke" icon="revoke" round></van-button></van-col>
          <van-col span="3"><van-button type="primary" size="large" @click="show = true" icon="plus" round></van-button></van-col>
        </van-row>
      </div>
    </div>
    <div class="shezhi" v-show="active == 2">
      <div class="biaodan">
        <van-cell-group inset>
          <van-field v-model.number="deviceSet.setTempCalibration" @click-input="handleClickInput1" type="number" label="温度校准(℃)" placeholder="温度校准(℃)" input-align="right" />
          <van-field v-model.number="deviceSet.setTemp" @click-input="handleClickInput2" type="number" label="设置温度(℃)" placeholder="设置温度(℃)" input-align="right" />
          <van-field v-model.number="deviceSet.coolDelay" @click-input="handleClickInput3" type="number" label="制冷延迟(min)" placeholder="制冷延迟(min)" input-align="right" />
        </van-cell-group>
      </div>
      <div style="width:100%;margin: 5% 0 0 0;">
        <van-button round block type="primary" :disabled="sendZhiLingshow" @click="sendZhiLing">发送指令</van-button>
      </div>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="desktop-o">操作</van-tabbar-item>
      <van-tabbar-item icon="orders-o">任务</van-tabbar-item>
      <van-tabbar-item icon="setting-o">设置</van-tabbar-item>
    </van-tabbar>
  </div>

</template>
<script>
import { connect } from "mqtt";
import Vue from "vue";
import Vant from "vant";
import { Notify } from "vant";
import HighCharts from "highcharts";
import moment from "moment";
Vue.use(Notify);
Vue.use(Vant);
export default {
  props: ["serialNumber"],
  data() {
    let that = this;
    return {
      value1: 10,
      value2: 0,
      switchChecked: false,
      show: false,
      xianShi: false,
      zhiLeng: "black",
      xunHuan: "black",
      wenKong: "black",
      jiAre: "black",
      zhiLeng1: "black",
      input: "",
      currentMqttClient: null,
      receiveTopic: "/device/hwsy02/" + that.serialNumber + "/deviceMessage",
      sendTopic: "/device/hwsy02/" + that.serialNumber + "/deviceSet",
      offlineTopic: "/device/device_hwsy02_" + that.serialNumber + "/offline",
      deviceMessage: {},
      deviceSet: {
        setTempCalibration: 0,
        setTemp: 0,
        coolKey: null,
        xunhuanLedKey: null,
        heatKey: null,
        systemKey: 0,
        coolDelay: 0,
      },
      active: 0,
      zhiLengshow: false,
      xunHuanshow: false,
      wenKongshow: false,
      sendZhiLingshow: false,
      tableData: [],
      loading: false,
      searchObj: {
        pageSize: 5,
        currentPage: 1,
        total: 0,
        onlyText: "",
      },
      searchObj1: {
        name: "",
        remark: "",
        interval: 10,
        executed: false,
      },
      setTempFlag1: false,
      setTempFlag2: false,
      setTempFlag3: false,
      cwChart: null,
    };
  },
  methods: {
    handleClickInput1() {
      let that = this;
      that.setTempFlag1 = true;
    },
    handleClickInput2() {
      let that = this;
      that.setTempFlag2 = true;
    },
    handleClickInput3() {
      let that = this;
      that.setTempFlag3 = true;
    },
    addRow() {
      let that = this;
      that.axios
        .post("HWSY02/Get_HWSY02_Task_Add", {
          name: that.searchObj1.name,
          serialNumber: that.serialNumber,
          remark: that.searchObj1.remark,
          interval: that.searchObj1.interval,
          executed: that.searchObj1.executed,
        })
        .then((response) => {
          if (response.data.code == 101) {
            that.$notify({ type: "success", message: "保存成功" });
            that.show = false;
            that.getList();
            that.searchObj1.name = "";
            that.searchObj1.remark = "";
          }
        });
    },
    deleteRow(row) {
      let that = this;
      that.$dialog
        .confirm({
          message: "确定删除吗",
        })
        .then(function () {
          that.axios
            .post("HWSY02/Del_HWSY02_Task", {
              onlyInt: row.id,
            })
            .then((response) => {
              if (response.data.code == 101) {
                that.getList();
              }
            });
        })
        .catch(function () {
          // on cancel
        });
    },
    revoke() {
      this.getList();
    },
    getList() {
      let that = this;
      that.loading = true;
      that.axios
        .post("HWSY02/Get_HWSY02_Task_List", {
          pageSize: that.searchObj.pageSize,
          currentPage: that.searchObj.currentPage,
          total: that.searchObj.total,
          onlyText: that.serialNumber,
        })
        .then((response) => {
          that.loading = false;
          that.tableData = response.data.data.data;
          that.searchObj.total = response.data.data.total;
        });
    },
    sendZhiLing() {
      let that = this;
      that.currentMqttClient.publish(
        that.sendTopic,
        JSON.stringify(that.deviceSet)
      );
      that.setTempFlag1 = false;
      that.setTempFlag2 = false;
      that.setTempFlag3 = false;
      that.sendZhiLingshow = true;
      setTimeout(function () {
        that.sendZhiLingshow = false;
      }, 1000);
    },
    handleZhiLeng() {
      let that = this;
      that.deviceSet.coolKey = !that.deviceSet.coolKey;
      that.currentMqttClient.publish(
        that.sendTopic,
        JSON.stringify(that.deviceSet)
      );
      that.zhiLengshow = true;
      setTimeout(function () {
        that.zhiLengshow = false;
      }, 1000);
    },
    handleXunHuan() {
      let that = this;
      that.deviceSet.xunhuanLedKey = !that.deviceSet.xunhuanLedKey;
      that.currentMqttClient.publish(
        that.sendTopic,
        JSON.stringify(that.deviceSet)
      );
      that.xunHuanshow = true;
      setTimeout(function () {
        that.xunHuanshow = false;
      }, 1000);
    },
    handlWenKong() {
      let that = this;
      that.deviceSet.heatKey = !that.deviceSet.heatKey;
      that.currentMqttClient.publish(
        that.sendTopic,
        JSON.stringify(that.deviceSet)
      );
      that.wenKongshow = true;
      setTimeout(function () {
        that.wenKongshow = false;
      }, 1000);
    },
    showNotify() {
      this.xianShi = true;
    },
    loadCharts() {
      let that = this;
      that.cwChart = HighCharts.chart("cwChart", {
        lang: {
          viewFullscreen: that.$t("lang.全屏"),
          printChart: that.$t("lang.打印图表"),
          downloadJPEG: that.$t("lang.导出JPEG"),
          downloadPDF: that.$t("lang.导出PDF"),
          downloadPNG: that.$t("lang.导出PNG"),
          downloadSVG: that.$t("lang.导出SVG"),
        },
        tooltip: {
          formatter: function () {
            return (
              this.series.name +
              "<br />" +
              that.$t("lang.温度") +
              "：" +
              this.y +
              "℃<br />" +
              that.$t("lang.时间") +
              "：" +
              moment(this.x).format("YYYY-MM-DD HH:mm:ss")
            );
          },
        },
        // plotOptions: { series: { turboThreshold: 0 } }, //大于一千个点要用这个。否则不显示。
        title: { text: that.$t("lang.温度曲线图") },
        credits: { enabled: false },
        //global: { useUTC: false },这里要在开头的时候初始化，而不是这里。
        yAxis: { title: { text: that.$t("lang.温度") + "℃" } },
        xAxis: {
          type: "datetime",
          labels: {
            formatter: function () {
              return HighCharts.dateFormat(
                "%Y-%m-%d %H:%M:%S",
                this.value + 8 * 60 * 60 * 1000
              );
            },
          },
        },
        series: [{ name: "PV", data: [] }],
      });
    },
  },
  mounted() {
    document.title = "恒温水浴";
    let that = this;
    that.loadCharts();
    that.currentMqttClient = connect(process.env.VUE_APP_MQTT_Url, {
      username: "phone",
      password: "sumj@86728803",
      clientId:
        "phone_hwsy02_" +
        that.serialNumber +
        "_" +
        Math.random().toString(16).substring(2, 8),
    });
    that.currentMqttClient.subscribe(that.receiveTopic, 0, (error) => {
      if (error) {
        //提示用户刷新页面
      }
    });

    that.currentMqttClient.subscribe(that.offlineTopic, 0, (error) => {
      if (error) {
        //提示用户刷新页面
      }
    });
    that.currentMqttClient.on("message", (topic, payload) => {
      if (topic == that.receiveTopic) {
        let strResult = "";
        for (let i = 0; i < payload.length; i++) {
          strResult += String.fromCharCode(payload[i]);
        }
        that.deviceMessage = JSON.parse(strResult);
        that.cwChart.series[0].addPoint([
          moment().valueOf(),
          parseFloat(that.deviceMessage.PV.toFixed(2)),
        ]);
        if (that.setTempFlag1 == false) {
          that.deviceSet.setTempCalibration = parseFloat(
            that.deviceMessage.TempCalibration.toFixed(2)
          );
        }
        if (that.setTempFlag2 == false) {
          that.deviceSet.setTemp = parseFloat(that.deviceMessage.SV.toFixed(2));
        }
        if (that.setTempFlag3 == false) {
          that.deviceSet.coolDelay = that.deviceMessage.CoolDelay;
        }
        if (that.deviceSet.coolKey !== that.deviceMessage.coolKey) {
          that.deviceSet.coolKey = that.deviceMessage.coolKey;
        }
        if (that.deviceSet.xunhuanLedKey !== that.deviceMessage.xunhuanLedKey) {
          that.deviceSet.xunhuanLedKey = that.deviceMessage.xunhuanLedKey;
        }
        if (that.deviceSet.heatKey !== that.deviceMessage.heatKey) {
          that.deviceSet.heatKey = that.deviceMessage.heatKey;
        }
        if (that.deviceMessage.coolKey == false) {
          that.zhiLeng = "black";
        } else if (that.deviceMessage.coolKey == true) {
          that.zhiLeng = "red";
        }
        if (that.deviceMessage.heatKey == false) {
          that.wenKong = "black";
        } else if (that.deviceMessage.heatKey == true) {
          that.wenKong = "red";
        }
        if (that.deviceMessage.coolLed == false) {
          that.zhiLeng1 = "black";
        } else if (that.deviceMessage.coolLed == true) {
          that.zhiLeng1 = "red";
        }
        if (that.deviceMessage.heatLed == false) {
          that.jiAre = "black";
        } else if (that.deviceMessage.heatLed == true) {
          that.jiAre = "red";
        }
        if (that.deviceMessage.xunhuanLedKey == false) {
          that.xunHuan = "black";
        } else if (that.deviceMessage.xunhuanLedKey == true) {
          that.xunHuan = "red";
        }
      } else if (topic == that.offlineTopic) {
        let strResult = "";
        for (let i = 0; i < payload.length; i++) {
          strResult += String.fromCharCode(payload[i]);
        }
        that.xianShi = strResult == 0;
      }
    });
    that.getList();
    that.axios
      .get("Base_Device/GetPWD", {
        params: {
          serialNumber: that.serialNumber,
        },
      })
      .then((response) => {
        that.deviceSet.systemKey = response.data;
      });
  },
};
</script>
<style scoped>
.shezhi {
  width: 100%;
  flex-wrap: nowrap;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
}

.renwu {
  width: 100%;
  height: 70px;
  flex-wrap: nowrap;
  display: -webkit-flex;
  flex-direction: column;
}

.caozuo {
  flex-direction: row;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  height: 70%;
  width: 100%;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: wrap;
}

.zuo {
  width: 75%;
  height: 300px;
  background-color: rgb(205, 245, 245);
  display: -webkit-flex;
  flex-direction: column;
}

.you {
  width: 25%;
  height: 290px;
  background-color: rgb(205, 245, 245);
  flex-direction: column;
  display: -webkit-flex;
  justify-content: space-between;
  padding: 5px 0 5px 0;
}

.shang {
  width: 100%;
  height: 72%;
  flex-direction: column;
  justify-content: space-between;
  display: -webkit-flex;
  background-color: rgb(205, 245, 245);
  margin: 2% 0% 0% 2%;
}

.xia {
  width: 100%;
  height: 28%;
  display: -webkit-flex;
  background-color: rgb(205, 245, 245);
}
.tubiao {
  width: 100%;
  height: 200px;
  margin: 2% 0 0 0;
}
</style>